.attachment-wrapper {
	.ticket-attachment{
		float: left;
		i {
			line-height: 40px;
			margin-right: 15px;
		}
		span {
			font-size: 13px;
		}
	}
	.ticket-button {
		height: 40px;
		line-height: 16px;
		padding-top: 6px;
		a {
			padding: 3px 10px;
		}
	}
}