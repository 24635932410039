@media print {
	.page-sidebar {
		display: none;
	}
	.list-group {
		display: none;
	}
	.grido {
		.filter {
			display: none !important;
		}
		tfoot {
			display: none;
		}
		.checker {
			display: none;
		}
		.actions {
			display: none;
		}
	}
	.page-content {
		margin-left: 0px;
	}
	.widget{
		.widget-body{
			.status-table{
				width: 33.33333333%;
				padding:5px;
				.stat-icon{
					width: 25%;
					font-size: 20px;
				}
				.stat-container{
					width: 75%;
					.stat-body{
						font-size: 14px;
						line-height: 24px;
					}
				}
			}
			hr{
				margin-top: 5px;
				margin-bottom: 10px;
			}
			.nofile{
				font-size: 14px;
				margin: 10px 0px;
			}
			.nofile-text{
				font-size: 14px;
			}

			.info-container-wrapper, .info-container-wrapper-times{
				width: 50%;
				float: left;
				.info-container{
					padding-top: 10px;
					.inf-header{
						font-size: 14px;
						i{
							font-size: 14px;
						}
					}
					hr{
						margin-top: 5px;
						margin-bottom: 10px;
					}
					.inf-body {
						.inf-col {
							font-size: 12px;
							padding: 5px 15px;
							.i-text{
								font-size: 12px;
								width: 120px;
							}
						}
					}
				}
			}
			.denied-text{
				font-size: 14px;
			}
			.list-group{
				display: block !important;
				.list-group-item{
					display: block !important;
					padding: 5px;
					span{
						font-size: 12px;
					}
				}
			}
		}
	}


	a[href]:after {
		content: none !important;
	}
	a[href]:after {
		content: " (" attr(href) ")";
	}
}