.qest-logo {
	background-image: url(../img/qest/qest-logo.svg);
}
.qest-logo-hover {
	background-image: url(../img/qest/qest-logo-hover.svg);
}
.qest-logo-icon {
    background-repeat: no-repeat;
    background-position: 50%;
	
	height: 16px;
	
	.qest-logo;
}
.sidebar-menu {
	a {
		&:hover {
			.qest-logo-icon {
				.qest-logo-hover;
			}
		}
	}
}