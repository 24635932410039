.statistics-tooltip {
    opacity: 0;
    position: absolute;
    background: rgba(0, 0, 0, .7);
    color: white;
    padding: 3px;
    border-radius: 3px;
    -webkit-transition: all .1s ease;
    transition: all .1s ease;
    pointer-events: none;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    text-align: left;
    z-index: 10000;
}
