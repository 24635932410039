.wizard {
	&.darkorange {
		ul {
			li {
				.step {
					transition: all 0.25s;
				}
				&.active {
					.step {
						color: #ed4e2a;
						border-color: #ed4e2a;
					}
					&:before {
						background-color: #ed4e2a;
					}
				}
				a {
					text-decoration: none;
					&:hover {
						.step {
							color: #fff;
							border-color: #ed4e2a;
							background-color: #ed4e2a;
						}
					}
				}
				@media (max-width: 580px) {
					margin-bottom: 15px;
					margin-right: auto;
					.title,
					strong {
						display: none;
					}
				}
			}
		}
	}
}
.wizard {
	&.wizard-wired {
		ul {
			li {
				width: 14%;
			}
		}
		a {
			color: black;
		}
	}
	& + .step-content {
		.form-group {
			min-height: 75px;
			margin-bottom: 0;
			padding-bottom: 20px;
			position: relative;
			.form-error-message {
				position: absolute;
				bottom: 2px;
				margin: 0;
				pointer-events: none;
			}
		}
	}
}
.summary-order-table {
	tr {
		th {
			border-top: 0 !important;
		}
		&:nth-child(odd) {
			background-color: #fbfbfb;
		}
	}
}
.insignificant-addresses {
	float: right;
	@media (max-width: 1500px) {
		width: 100%;
	}
	.ins-header {
		font-size: 20px;
		font-weight: 100;
		border-bottom: 1px solid #e5e5e5;
		line-height: 40px;
		margin-bottom: 15px;
		i {
			float: right;
			line-height: 45px;
		}
		&:hover {
			cursor: pointer;
		}
		& + .row{
			display: none;
		}
	}
}
.info-container .inf-body .inf-col .i-text{
	width: 50%;
	min-width: 150px;
	padding-right: 15px;
}
