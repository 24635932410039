.order-form {
	.form-title {
		font-weight: 600;
	}
}
.file-upload-list {
	i {
		margin-right: 10px;
	}
}
.step-pane {
	.nofile {
		color: #BFBFBF;
		font-size: 80px;
		margin: 30px 0 10px;
	}
	.nofile-text {
		font-size: 22px;
		color: #BFBFBF;
	}
}
.step-5-style {
	.info-container {
		padding-top: 15px;
		padding-bottom: 5px;
	}
}
.toggle-menu-top {
	margin-bottom: 0;
	border: 1px #ccc solid;
	box-shadow: 0 0 3px rgba(0,0,0,0.2);
	li {
		display: inline-block;
		border-radius: 0 0 0 0;
		margin-right: -3px;
		background-color: #E9E9E9;
		padding: 10px 20px;
		color: #929292;
		transition: all 0.25s;
		border-right: 1px #CACACA solid;
		&.active {
			color: #333;
			background-color: #FFFFFF;
			box-shadow: 0 -1px 0 #ED4E2A;
		}
		&:hover {
			background-color: #FFFFFF;
			color: #333;
		}
	}
	a {
		text-decoration: none;
		color: #333;
	}
}
.toggle-menu-top-activation {
	margin-bottom: 0;
	border: 1px #ccc solid;
	box-shadow: 0 0 3px rgba(0,0,0,0.2);
	li {
		display: inline-block;
		border-radius: 0 0 0 0;
		margin-right: -3px;
		background-color: #E9E9E9;
		padding: 10px 20px;
		color: #929292;
		transition: all 0.25s;
		border-right: 1px #CACACA solid;
		&.active {
			color: #333;
			background-color: #FFFFFF;
			box-shadow: 0 -1px 0 #5DB2FF;
		}
		&:hover {
			background-color: #FFFFFF;
			color: #333;
		}
	}
	a {
		text-decoration: none;
		color: #333;
	}
}
.active-allow {
	textarea.form-control {
		height: auto;
		min-height: 100px;
		max-width: 100%;
	}
	input[type="submit"] {
		margin-left: 15px;
	}
}
input[type=checkbox]:checked~.text:before,
input[type=checkbox]~.text:before,
input[type=radio]:checked~.text:before,
input[type=radio]~.text:before {
	background-color: #fff;
}
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
textarea,
select,
.grido .operations select,
.grido .count select{
	background-color: #fff;
}
.step-content select, .step-content textarea{
	background-color: #fff;
	border-radius: 0;
}
.step-content textarea{
	min-height: 80px;
	max-width: 100%;
	min-width: 100%;
}
.file-caption{
	background-color: #fff;
	border-radius: 0;
}
.btn-darkorange.btn.btn-file{
	border-radius: 0 !important;
}
.service-input:nth-child(2n){
	@media (min-width: 992px){
		clear: right;
	}
}
