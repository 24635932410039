.denied {
	color: #BFBFBF;
    font-size: 50px;
    margin-bottom: 10px;
}
.denied-text {
	font-size: 22px;
    color: #BFBFBF;
    font-weight: lighter;
}
.denied-button {
	padding: 20px;
	.btn-denied {
		padding: 15px 20px;
		border: 1px #BFBFBF dashed;
		i {
			display: block;
			font-size: 30px;
			margin: 10px;
			margin-top: 0px;
		}
	}
}