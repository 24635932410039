.donut-headline {
	position: absolute;
    top: 125px;
    left: 50%;
    .transform(translateX(-50%));
}


.easyPieChart {
  position: relative;
  text-align: center;
}

.easyPieChart canvas {
  position: absolute;
  top: 8px;
  left: 8px;
}

.easy-pie-chart {
  display: inline-block;
}

.databox-piechart .font-90 {
  font-size: 90%;
  position: relative;
  left: 8px;
  top: 8px;
}