.page-sidebar {
	z-index: 10;
}
.form-error-message {
	display: block;
    margin-top: 5px;
    margin-bottom: 3px;
	color: #e46f61;
}
.dropdown-Parent{
	position:relative;
}
.select2:focus{
	outline:none !important;
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area {
	right: 0px;
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area .add-order .avatar {
	border-left: 0px;
	i {
		line-height: 29px;
	}
}
.login-container {
	.loginbox {
		padding: 15px;
	}
	.loginbox-title {
		margin-bottom: 25px;
	}
	input[type=submit] {
		margin-top:15px;
	}
}
.databox .databox-left {
	padding: 0px;
}
.databox-text {
	text-transform: uppercase;
}
.databox  {
	cursor: pointer;
	&.active {
		box-shadow: 0 0 4px rgba(0,0,0,.3);
		.databox-right {
			border-bottom: 3px solid;
		}
	}
}
input[type="text"] {
	background-color: white;
	&:focus, &:active {
		border-color: #ED4E2A;
		&:hover {
			border-color: #ED4E2A;
		}
	}
	&:hover {
		border-color: #AAA;
	}
}
.btn-darkorange {
	border: 1px #ed4e2a solid;
	&:hover, &:focus, &:focus:hover {
		color: white !important;
		border: 1px darken(#ed4e2a, 5%) solid;
	}
}
.page-breadcrumbs {
	min-height: 38px;
    line-height: 38px;
}
.page-header {
	height: 57px;
	.header-title {
		height: 57px;
		h1 {
			margin: 0 10px;
			line-height: 55px;
			font-size: 32px;
			white-space: nowrap; 
			overflow: hidden;
			text-overflow: ellipsis;
			@media all and (max-width: 1200px) {
				width: 600px;
				font-size: 28px;
			}
			@media all and (max-width: @screen-md) {
				font-size: 24px;
				width: 500px; 
			}
			@media all and (max-width: @screen-sm) {
				font-size: 20px;
				width: 400px;
			}
			@media all and (max-width: @screen-xs) {
				width: 300px; 
			}
		}
	}
}
.wizard ul li.active{
	.title {
		small {
			margin-left: -5px; 
		}
	}
}
.select2-container .select2-selection--multiple {
	min-height: 34px;
}
#frm-orderPreferedTimeForm-concrete_day {
	margin-top: 0px;
}
.loading-container {
	z-index: 99999999;
}
@media all and (max-width: 480px) {
	.wizard {
		.steps {
			li {
				&.q-active {
					display: block !important;
					width: 100%;
				}
				display: none !important;
			}
		}
	}
}
.label {
	font-size: 13px;
}
.state-active{ 
	.panel{
		overflow-x: auto;
		& > .first-panel{
			margin-bottom: 20px;
		}
	}
}
.navbar .sidebar-collapse {
	@media all and(max-width: 530px) {
		float: left;
		display: block;
		position: relative;
	}
}
.databox{
	.databox-stat{
		.icon{
			font-size: 14px;
		}
	}
}