.history-table {
	width: 100%;
}

.status-table {
	border-left: 3px red solid;
	background-color: white;
	padding: 15px 5px;
	@media all and (max-width: @screen-xs-max) {
		margin-bottom: 15px;
	}
	.stat-icon {
		font-size: 40px;
		text-align: center;
		border-right: 1px #DDD solid;
		@media all and (max-width: @screen-sm-max) {
			border-right: 0px;
			margin-bottom: 10px;
		}
	}
	.stat-container {
		.stat-header {
			font-weight: bold;
		}
		.stat-body {
			font-weight: 100;
			font-size: 18px;
			line-height: 36px;
		}
	}
}

.tickets-container {
	padding: 5px 15px;
	background-color: transparent;
	.tickets-list  {
		.ticket-item {
			.ticket-user {
				height: 60px;
				i {
					font-size: 32px;
					line-height: 40px;
					vertical-align: -3px;
				}
				.user-company {
					color: #333;
					font-size: 16px;
				}
				.user-name {
					font-size: 16px;
				}
			}
			.divider {
				height: 60px;
			}
			.ticket-stime {
				height: 30px;
				padding: 4px 17px;
				i {
					font-size: 18px;
					line-height: 0px;
					vertical-align: 0px;
					margin-right: 10px;
				}
			}
		}
	}
	.ticket-button {
		text-align: right;
		line-height: 40px;
		@media all and (max-width: @screen-md-max) {
			text-align: center;
			a {
				width: 100%;
			}
		}
	}
}
.info-container-wrapper{
	@media all and (max-width: 1500px) {
		width:50%;
	}
	@media all and (max-width: 1050px) {
		width:100%;
	}
	&:last-child .info-container{
		border-bottom: none !important;
	}
	.info-container {
		@media all and (max-width: 1050px) {
			margin-bottom:20px;
			border-bottom: 1px solid #e5e5e5;
		}
	}
}
.info-container {
	padding: 0px 5px 15px 5px;
	.inf-header {
		font-size: 20px;
		font-weight: 100;
		@media all and (max-width: 1500px) {
			font-size: 18px;
		}
		i {
			margin-right: 5px;
			font-size: 24px;
			@media all and (max-width: 1500px) {
				font-size: 22px;
			}
		}
	}
	.inf-body {
		margin-top: 15px;
		.inf-col {
			font-weight: 100;
			font-size: 18px;
			padding: 5px 15px;
			@media all and (max-width: 1500px) {
				font-size: 16px;
			}
			&:nth-child(2n) {
				background-color: white;
			}
			.i-text {
				font-size: 14px;
				width: 170px;
				@media all and (max-width: 1500px) {
					width: 150px;
				}
				display: inline-block;
				font-weight: bold;
				@media all and (max-width: 500px) {
					display: block;
					margin-bottom: 5px;
				}
			}
		}
	}
	.inf-footer {
		padding: 15px;
	}
	.tickets-container {
		.tickets-list  {
			padding-top: 15px;
			.ticket-user {
				padding: 3px 10px;
				height: 32px;
			}
			.ticket-name {
				width: calc(~"100% - 125px");
				@media all and(max-width: @screen-xs-max) {
					width: 100%;
				}
				i {
					font-size: 17px;
					line-height: 25px;
					vertical-align: -1px;
				}
				.user-name {
					font-size: 12px;
				}
			}
			.ticket-button {
				position: absolute;
				top: 0px;
				right: 0px;
				padding: 3px 3px;
				line-height: 8px;
				a {
					padding: 3px 6px;
					font-size: 13px;
				}

				@media all and (max-width: @screen-md-max) {
					text-align: right;
					a {
						width: auto;
					}
				}
				@media all and (max-width: @screen-xs-max) {
					position: relative;
					text-align: center;
					a {
						width: 70%;
						&:last-child{
							width: 29%;
						}
					}
				}
			}
		}
	}
}
.new-activation {
	.table thead>tr>th {
		line-height: 32px;
	}
	.table {
		tr:last-child {
			width: 20px;
		}
		th:last-child {
			width: 20px;
		}
	}
}
.new-activation-headline {
	i {
		margin-right: 10px;
		vertical-align: 2px;
	}
	h2 {
		margin-bottom: 21px;
	}
}
.grido {
	.filter {
		.select2-container .select2-selection--single {
			height: 30px;
		}
		.select2-container--default .select2-selection--single .select2-selection__arrow b:before {
			line-height: 30px;
		}
		.select2-container--default .select2-selection--single .select2-selection__rendered {
			line-height: 28px;
		}
	}
}
.select2-container--default .select2-results__option[aria-selected=true] {
	background-color: #474544;
	border-radius: 5px;
	color: white;

}
.select2-container--default .select2-results__option--highlighted {
	background-color: #eee;
	border-radius: 5px;
}
.select2-container--default .select2-results__options {
	li:first-child {
		margin-top: 5px;
	}
}