.fc-unthemed {
	//Style Here!!!
	.fc-event{
		cursor: pointer;
	}
	.fc-event-container {
		.fc-monthRender {
			.fc-time {
				font-size: 13px;
				display: inline-block;
			}
			.fc-title {
				font-size: 13px;
				float:right;
				display: inline-block;
				text-align: right;
			}
			> div {
				display:none;
				> div {
					height: 16px;
					.left {
						display: inline-block;
						font-weight: bold;
						float:left;
						i {
							width: 13px;
							vertical-align: 1px;
						}
					}
					.right {
						display: inline-block;
						float:right;
					}
				}
			}
		}
		.fc-weekRender {
			.fc-time {
				font-size: 13px;
				display: block;
			}
			.fc-title {
				font-size: 13px;
				display: block;
			}
		}
		.fc-dayRender {
			> div {
				height: 16px;
				.left {
					display: inline-block;
					font-weight: bold;
					i {
						width: 13px;
						vertical-align: 1px;
					}
				}
				.right {
					display: inline-block;
				}
			}
		}
		hr {
			margin-top: 4px;
			margin-bottom: 6px;
			border-bottom: 1px white solid;
		}
	}
}
#fullCalModal {
	&.modal {
		text-align: center;
		padding: 0!important;
	}

	&.modal:before {
		content: '';
		display: inline-block;
		height: 100%;
		vertical-align: middle;
		margin-right: -4px;
	}

	.modal-dialog {
		display: inline-block;
		text-align: left;
		vertical-align: middle;
	}

	#modalBody {
		>div {
			.left {
				width: 85px;
				display: inline-block;
				font-weight: bold;
				i {
					width: 18px;
					vertical-align: 1px;
				}
			}
			.right {
				margin-left: 15px;
				display: inline-block;
			}
		}
	}
}