.grido {
    
	tbody {
		th {
			&:first-child {
				border-top: 2px #CACACA solid !important;
			}
		}
	}
	thead {
		a {
			color: #000;
		}
		.head {
			th {
				height: 30px !important;
				padding: 7px !important;
			}
		}
	}
    

	.no-results {
		padding: 45px;
		font-size: 18px;
		font-weight: bold;
	}
	.filter.outer {
		background-color: white;
		border-top: 3px #ED4E2A solid;
		padding-top: 8px;

        .items {
          span[id^=frm][id$=message] {
            display: none;
          }
        }

		.buttons {
			width: 100%;
			background-color: #E9E9E9;
			border-top: 1px #CACACA solid;
			padding: 10px;
			margin: 0px;
			margin-top: 10px;
			text-align:right;
			font-size: 13px;
			input[name*="search"]{
				.btn;
				background-color: #ed4e2a !important;
				border-color: #ed4e2a;
				color: #fff;
				font-size: 13px;
				&:hover {
					background-color: #ef694b !important;
					border-color: #ef694b;
					color: #fff;
				}
				&:focus {
					background-color: #ef694b !important;
					border-color: #ef694b;
					color: #fff;
				}
			}
			input[name*="reset"]{
				.btn;
				color: #444;
				background-color: #fff;
				border-color: #ccc;
				font-size: 13px;
				&:hover {
					border-color: #b8b8b8;
				}
				&:focus {
					border-color: #b8b8b8;
				}
			}
		}
	}
}

#snippet-activationGrid-grid,#snippet-ozActivationGrid-grid,#snippet-atActivationGrid-grid{
  .grido {

    tbody {
      th {
        &:first-child {
          border-top: 2px #CACACA solid !important;
        }
      }
    }
    thead {
      a {
        color: #000;
      }
      .head {
        th {
          height: 30px !important;
          padding: 7px !important;
        }
      }
    }


    .no-results {
      padding: 45px;
      font-size: 18px;
      font-weight: bold;
    }
    .filter.outer {
      background-color: white;
      border-top: 3px #5DB2FF solid;
      padding-top: 8px;

      .items {
        span[id^=frm][id$=message] {
          display: none;
        }
      }

      .buttons {
        width: 100%;
        background-color: #E9E9E9;
        border-top: 1px #CACACA solid;
        padding: 10px;
        margin: 0px;
        margin-top: 10px;
        text-align:right;
        font-size: 13px;
        input[name*="search"]{
          .btn;
          background-color: #5DB2FF !important;
          border-color: #5DB2FF;
          color: #fff;
          font-size: 13px;
          &:hover {
            background-color: #5DB2FF !important;
            border-color: #5DB2FF;
            color: #fff;
          }
          &:focus {
            background-color: #5DB2FF !important;
            border-color: #5DB2FF;
            color: #fff;
          }
        }
        input[name*="reset"]{
          .btn;
          color: #444;
          background-color: #fff;
          border-color: #ccc;
          font-size: 13px;
          &:hover {
            border-color: #b8b8b8;
          }
          &:focus {
            border-color: #b8b8b8;
          }
        }
      }
    }
  }
}


